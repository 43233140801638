import { useEffect, useState } from "react";
import { XyzTransitionGroup } from "@animxyz/react";

const SideBar = ({ about, home, portfolio, contact }) => {
  const [active, setActive] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting === true) {
          setActive(entry.target.id);
        }
      });
    });

    observer.observe(about.current);
    observer.observe(home.current);
    observer.observe(contact.current);
    observer.observe(portfolio.current);
  }, [about, home, portfolio, contact]);

  return (
    <div className="side-bar">
      <XyzTransitionGroup
        className="sidebar-ul"
        appear
        xyz="fade front-1 stagger-2 ease-out delay-2"
      >
        <li className={active === "Main" ? "lineActive" : "line"}></li>
        <li className={active === "Portfolio" ? "lineActive" : "line"}></li>
        <li className={active === "About" ? "lineActive" : "line"}></li>
        <li className={active === "Contact" ? "lineActive" : "line"}></li>
      </XyzTransitionGroup>

      <XyzTransitionGroup
        appear
        xyz="fade-85% left-4 stagger-2 ease-in-out delay-2"
        className="scroll-down"
        style={active === "Main" ? { opacity: 1 } : { opacity: 0 }}
      >
        <p>Scroll Down</p>
      </XyzTransitionGroup>
    </div>
  );
};

export default SideBar;
