import Main from "../containers/Main";
import About from "../containers/About";
import SideBar from "../components/SideBar";
import Contact from "../containers/Contact";
import Portfolio from "../containers/Portfolio";

import { useRef } from "react";

const Home = () => {
  const aboutRef = useRef();
  const mainRef = useRef();
  const contactRef = useRef();
  const portfolioRef = useRef();

  return (
    <div className="home-view">
      <SideBar about={aboutRef} home={mainRef} contact={contactRef} portfolio={portfolioRef} />
      <Main mainRef={mainRef} />
      <Portfolio portfolioRef={portfolioRef} />
      <About aboutRef={aboutRef} />
      <Contact contactRef={contactRef} />
    </div>
  );
};

export default Home;
