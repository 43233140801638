import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, CardActionArea, CardMedia } from "@mui/material";

const Portfolio = ({ portfolioRef }) => {
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    async function loadPortfolio(type) {
      fetch(`https://wpcms.gregdesign.hu/wp/wp-json/acf/v3/${type}`)
        .then((res) => res.json())
        .then(
          (result) => {
            setPortfolio(result);
            console.log("Posts: ", result);
          },
          (error) => {
            console.error(error);
          }
        );

      setPortfolio(portfolio);
    }

    loadPortfolio("portfolios");
  }, []);

  return (
    <div id="Portfolio" ref={portfolioRef} className="portfolio">
      {portfolio !== null ?
        portfolio.map(({ id, acf }) => (
          // <dir className="portfolio-card" key={id}>
          <Grid item xs={4} key={id}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="170"
                  image={acf.cover.sizes.medium_large}
                  alt="green iguana"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: acf.title }}
                  />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    dangerouslySetInnerHTML={{ __html: acf.content }}
                  />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          //   <p dangerouslySetInnerHTML={{ __html: acf.title }}></p>
          //   <section dangerouslySetInnerHTML={{ __html: acf.content }}></section>
          //   <img src={acf.cover.sizes.medium_large} alt="cover img" />
          //   <button>Details</button>
          // </dir>
        )) : 
          <div>Loading...</div>
        } 
    </div>
  );
};

export default Portfolio;
