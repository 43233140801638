import { RiMenu3Line, RiCloseLine, RiFacebookCircleFill, RiGithubFill } from "react-icons/ri";
import { useState } from "react";
import { Fade as Hamburger } from "hamburger-react";

const NavBar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigation = [
    { link: "Portfolio", text: "Portfolio" },
    { link: "About", text: "About" },
    { link: "Contact", text: "Contact" },
  ];

  const handleClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setToggleMenu(false);
  };

  return (
    <div className="header">
      <nav className="nav-bar">
        <a className="home-button" onClick={() => handleClick("Home")}>
          © Code by Nagy Gergő
        </a>

        <section className="navbar-flex">
          <div className="navbar-flex social-icons">
            <a href="https://www.facebook.com/gregdesignBynagygergo" target="_blank">
              <RiFacebookCircleFill />
            </a>
            <a href="https://github.com/GergoNagy" target="_blank">
              <RiGithubFill />
            </a>
          </div>

          <div className="navbar-burger-menu">
            <Hamburger toggled={toggleMenu} toggle={setToggleMenu} size={27}  easing="ease-in"/>
            {/* {toggleMenu ? (
              <RiCloseLine color="#f2f2f2" size={27} onClick={() => setToggleMenu(false)} />
            ) : (
              <RiMenu3Line color="#f2f2f2" size={27} onClick={() => setToggleMenu(true)} />
            )} */}
          </div>
        </section>
      </nav>
      {toggleMenu && (
        <div className="navbar-links">
          {navigation.map((nav) => (
            <a key={nav.text} className="navbar-link" onClick={() => handleClick(nav.link)}>
              {nav.text}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavBar;
