import welcomeImg from "../assets/main-bg.jpg";

const Main = ({ mainRef }) => {
  return (
    <div id="Home" className="main-component">
      <section className="welcome-text">
        <h1>
          Nagy <br />
          Gergő
        </h1>
        <h3 ref={mainRef} id="Main">
          <span style={{ "--delay": 0.1 + "s" }}>Developer &</span>
          <br />
          <span style={{ "--delay": 0.3 + "s" }}>Web Designer</span>
        </h3>
        <p>
          <span style={{ "--delay": 0.5 + "s" }}>
            Helping brands to stand out in the digital era.
          </span>
          <br />
          <span style={{ "--delay": 0.7 + "s" }}>Together we will set the new status quo.</span>
          <br />
          <span style={{ "--delay": 0.9 + "s" }}>No nonsense, always on the cutting edge.</span>
        </p>
      </section>
      <section className="welcome-img">
        <img src={welcomeImg} alt="welcome img" />
      </section>
    </div>
  );
};

export default Main;
